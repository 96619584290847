/**
 * Core
 */
import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
/**
 * Styles
 */
import "./Select.scss";

/**
 * Assets
 */
import arrowImg from "../../../images/select_arrow.svg";

export const Select = ({
  options,
  value,
  onChange,
  name,
  id,
  placeholder,
  error,
  errorText,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);

  /**
   * Ge list of items for dropdown l;ist
   * @param {Object} values Selected value
   * @returns {*[]}
   */
  const getListItems = (values = {}) => {
    const content = [];

    let iteration = 0;
    for (const [key, label] of Object.entries(values)) {
      content.push(
        <div key={`d_${name}_${iteration}`} onClick={() => handleSelect(key)}>
          {label}
        </div>
      );

      iteration++;
    }
    return content;
  };
  const dropDownContent = getListItems(options);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (option) => {
    onChange(option);
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={"input__wrapper"}>
      <div className={"select" + (error ? " error" : "")} ref={selectRef}>
        <div className="selected-value" onClick={toggleOpen}>
          <input
            type="text"
            className="selected-value"
            value={value}
            onClick={toggleOpen}
            placeholder={placeholder}
            name={name}
            id={id ?? `${name}Id`}
            autoComplete="off"
            readOnly
          />
          <img
            src={arrowImg}
            className={`arrow-icon ${isOpen ? "open" : ""}`}
            alt="arrow"
          />
        </div>
        {isOpen && <div className="options">{dropDownContent}</div>}
      </div>
      {errorText && <p className={"error-text form"}>{errorText}</p>}
    </div>
  );
};
Select.propTypes = {
  placeholder: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  id: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  options: PropTypes.object,
};
