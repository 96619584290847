/**
 * Core
 */
import PhoneInput from "react-phone-input-2";
import PropTypes from "prop-types";
/**
 * Styles
 */
import "react-phone-input-2/lib/style.css";
import "./PhoneNumberInput..scss";

function PhoneNumberInput({
  value,
  handleChange,
  containerClass,
  dropdownClass,
  inputClass,
  buttonClass,
  error,
  errorText,
}) {
  return (
    <>
      <div className={"input__wrapper"}>
        <PhoneInput
          country={"ua"}
          value={value ? value : "+380"}
          onChange={handleChange}
          containerClass={containerClass}
          dropdownClass={dropdownClass}
          inputClass={inputClass + (error ? " error" : "")}
          buttonClass={buttonClass + (error ? " error" : "")}
        />
        {errorText && <p className={"error-text form"}>{errorText}</p>}
      </div>
    </>
  );
}

PhoneNumberInput.propTypes = {
  inputClass: PropTypes.string,
  dropdownClass: PropTypes.string,
  containerClass: PropTypes.string,
  name: PropTypes.string,
  handleChange: PropTypes.func,
  value: PropTypes.string,
  error: PropTypes.bool,
  errorText: PropTypes.string,
};

export default PhoneNumberInput;
