import axios from "axios";

/**
 * Send get request to url
 * @param {string} url Request url
 * @param {Object|null} params List of params for axios library
 * @returns {Promise<AxiosResponse<any>>}
 */
export const get = (url, params = null) => axios.get(url, params);

/**
 * Send post request to url
 * @param {string} url Request url
 * @param {Object|null} attributes List of body request params
 * @param {Object|null} headers List of params for axios library
 * @returns {Promise<AxiosResponse<any>>}
 */
export const post = (url, attributes = null, headers = null) =>
  axios.post(url, attributes, headers);
