/**
 * Core
 */
import {InputGlobal} from "../InputGlobal";
import {TextArea} from "../TextArea";
import {LabelGlobal} from "../LabelGlobal";
import PhoneNumberInput from "../PhoneNumberInput";
import {useEffect, useRef, useState} from "react";
import {
  clearForm,
  closeForm,
  closeSuccessModal,
  fixLayoutForSuccessModal,
  validateAgencyName,
  validateEmail,
  validateName,
  validateNumberClients,
  validatePhoneNumber,
  validatePosition,
} from "../../../helpers";
import {Select} from "../Select";
import {getCountries, registrationRequest} from "../../../services";
import SpinLoader, {
  LOADER_COLOR_DARK,
  LOADER_SIZE_LARGE,
  POSITION_ABSOLUTE,
} from "../SpinLoader";
import ReCAPTCHA from "react-google-recaptcha";
import {SuccessModal} from "../../Modals/SuccessModal";
import {ERROR_STATUS_UNPROCESSABLE, STATUS_SUCCESS} from "../../../constants";
import {
  CANNOT_BE_BLANK,
  CAPTCHA_ERROR,
  RESPONSE_REGISTER_ERROR,
} from "../../../constants/errors";

export const RegistrationForm = () => {
  const [registrationData, setRegistrationData] = useState({});
  const [countries, setCountries] = useState({});
  const [firstNameError, setFirstNameError] = useState(null);
  const [lastNameError, setLastNameError] = useState(null);
  const [agencyNameError, setAgencyNameError] = useState(null);
  const [positionError, setPositionError] = useState(null);
  const [numberClientsError, setNumberClientsError] = useState(null);
  const [countryError, setCountryError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [phoneNumberError, setPhoneNumberError] = useState(null);
  const [responseError, setResponseError] = useState(null);
  const [loader, setLoader] = useState(false);
  const [captchaError, setCaptchaError] = useState(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const recaptchaRef = useRef(null);

  /**
   * Effect for get Countries list
   */
  useEffect(() => {
    getCountries().then(({data}) => {
      if (!data.success) {
        return false;
      }
      setCountries(data.data);
    });
    // eslint-disable-next-line
  }, []);

  /**
   * Send Request on Api for registration
   */
  const handleRegistration = () => {
    let isSuccessValidation = true;
    const resultPhoneNumber = validatePhoneNumber(registrationData?.phone);
    if (!resultPhoneNumber.status) {
      setPhoneNumberError(resultPhoneNumber.error);
      isSuccessValidation = false;
    }
    const resultFirstName = validateName(registrationData?.first_name);
    if (!resultFirstName.status) {
      setFirstNameError(resultFirstName.error);
      isSuccessValidation = false;
    }
    const resultLastName = validateName(registrationData?.last_name);
    if (!resultLastName.status) {
      setLastNameError(resultLastName.error);
      isSuccessValidation = false;
    }
    if (!registrationData?.residence_country) {
      setCountryError(CANNOT_BE_BLANK);
      isSuccessValidation = false;
    }
    const resultAgencyName = validateAgencyName(registrationData?.agency_name);
    if (!resultAgencyName.status) {
      setAgencyNameError(resultAgencyName.error);
      isSuccessValidation = false;
    }
    const resultPosition = validatePosition(registrationData?.position);
    if (!resultPosition.status) {
      setPositionError(resultPosition.error);
      isSuccessValidation = false;
    }
    const resultNumberClient = validateNumberClients(
        registrationData?.number_of_clients
    );
    if (!resultNumberClient.status) {
      setNumberClientsError(resultNumberClient.error);
      isSuccessValidation = false;
    }
    const resultEmail = validateEmail(registrationData.email);
    if (!resultEmail.status) {
      setEmailError(resultEmail.error);
      isSuccessValidation = false;
    }
    if (!registrationData?.reCaptcha) {
      setCaptchaError(CAPTCHA_ERROR);
      isSuccessValidation = false;
    }
    if (isSuccessValidation) {
      setLoader(true);
      registrationRequest(registrationData)
          .then((resp) => {
            cbLoginSuccess(resp);
          })
          .catch((err) => responseErrorCb(err));
    }
  };

  /**
   * Success api response
   * @param {object} response APi response object
   */
  const cbLoginSuccess = (response) => {
    if (response?.data && response?.status === STATUS_SUCCESS) {
      setRegistrationData({});
      setLoader(false);
      setShowSuccessModal(true);
    } else {
      setLoader(false);
    }
  };

  /**
   * Failed request on api
   * @param {object} responseErr failed request on api
   */
  const responseErrorCb = (responseErr) => {
    setLoader(false);
    if (responseErr.response.status === ERROR_STATUS_UNPROCESSABLE) {
      recaptchaRef.current?.reset();
      setRegistrationData({...registrationData, reCaptcha: ""});
      const {data} = responseErr.response;
      let errorText = "";
      data.errors.forEach((error) => {
        if (error.name === "first_name") {
          setFirstNameError(error.message);
        } else if (error.name === "last_name") {
          setLastNameError(error.message);
        } else if (error.name === "agency_name") {
          setAgencyNameError(error.message);
        } else if (error.name === "position") {
          setPositionError(error.message);
        } else if (error.name === "email") {
          setEmailError(error.message);
        } else if (error.name === "phone") {
          setPhoneNumberError(error.message);
        } else if (error.name === "number_of_clients") {
          setNumberClientsError(error.message);
        } else if (error.name === "residence_country") {
          setCountryError(error.message);
        } else if (error.name === "reCaptcha") {
          setCaptchaError(error.message);
        } else {
          errorText +=
              errorText.length > 0 ? " " + error.message : error.message;
        }
      });
    } else {
      setResponseError(RESPONSE_REGISTER_ERROR);
      recaptchaRef.current?.reset();
      setRegistrationData({...registrationData, reCaptcha: ""});
    }
  };

  /**
   * Handler for change Name
   * @param {object} event
   */
  const handleChangeName = (event) => {
    const {name, value} = event.target;

    if (name === "first_name") {
      setFirstNameError(null);
      setRegistrationData({
        ...registrationData,
        first_name: value,
      });
    } else if (name === "last_name") {
      setLastNameError(null);
      setRegistrationData({...registrationData, last_name: value});
    }
  };

  /**
   * Handle change agency name
   * @param {object} event
   */
  const handleChangeAgencyName = (event) => {
    setAgencyNameError(null);
    setRegistrationData({
      ...registrationData,
      agency_name: event?.target?.value,
    });
  };

  /**
   * Handle change position
   * @param {object} event
   */
  const handleChangePosition = (event) => {
    setPositionError(null);
    setRegistrationData({
      ...registrationData,
      position: event?.target?.value,
    });
  };

  /**
   * Handle change number of client
   * @param {object} event
   */
  const handleChangeNumberClient = (event) => {
    setNumberClientsError(null);
    setRegistrationData({
      ...registrationData,
      number_of_clients: event?.target?.value,
    });
  };

  /**
   * Handle change country
   * @param {string} value
   */
  const handleChangeCountry = (value) => {
    setCountryError(null);
    setRegistrationData({
      ...registrationData,
      residence_country: value,
    });
  };

  /**
   * Handle change registration email
   * @param {object} event
   */
  const handleChangeEmail = (event) => {
    setEmailError(null);
    setRegistrationData({...registrationData, email: event?.target?.value});
  };

  /**
   * Handle change phone Number
   * @param {string} value
   */
  const handlePhoneNumberChange = (value) => {
    setPhoneNumberError(null);
    setRegistrationData({...registrationData, phone: `+${value}`});
  };

  /**
   * Handle change comment
   * @param {object} event
   */
  const handleChangeComment = (event) => {
    setRegistrationData({...registrationData, comment: event?.target?.value});
  };

  /**
   * Handle change Google Captcha
   * @param {string} value recaptcha
   */
  const onCaptchaChange = (value) => {
    setCaptchaError(null);
    setRegistrationData({...registrationData, reCaptcha: value});
  };

  /**
   * Clear form after close
   */
  const clearRegisterForm = () => {
    setFirstNameError(null);
    setLastNameError(null);
    setAgencyNameError(null);
    setPositionError(null);
    setNumberClientsError(null);
    setCountryError(null);
    setPhoneNumberError(null);
    setEmailError(null);
    setCaptchaError(null);
    setResponseError(null);
    recaptchaRef.current?.reset();
    setRegistrationData({});
  };

  /**
   * Close Register Modal
   */
  const closeRegisterModal = () => {
    const cancelButton = document.querySelector(".cancel__global");
    const registerForm = document.querySelector(
        ".form__wrap.registration__form"
    );
    const closeIcon = document.querySelector(".close.register");
    closeForm(cancelButton, registerForm);
    closeForm(closeIcon, registerForm);
  };

  /**
   * Close Success Modal
   */
  const handleCloseSuccessModal = () => {
    closeSuccessModal(
        () => setShowSuccessModal(false),
        () => setRegistrationData({}),
        ".form__wrap.registration__form",
        ".form__header.register",
        ".form__inner.register"
    );
  };

  /*
   * Effect for clean Register Form
   */
  useEffect(() => {
    clearForm(".cancel__global", ".background__modal", ".close", () => {
      clearRegisterForm();
    });
  }, []);

  /*
   * Effect for layout success modal and close Register modal
   */
  useEffect(() => {
    if (!showSuccessModal) {
      closeRegisterModal();
    }
    fixLayoutForSuccessModal(
        ".form__header.register",
        ".background__modal.register",
        ".form__inner.register",
        showSuccessModal,
        () => setShowSuccessModal(false)
    );
  }, [showSuccessModal]);

  return (
      <>
        {loader && (
            <SpinLoader
                color={LOADER_COLOR_DARK}
                size={LOADER_SIZE_LARGE}
                position={POSITION_ABSOLUTE}
                withBlur={true}
                isFormBlur={true}
            />
        )}
        {showSuccessModal ? (
            <SuccessModal handleClick={handleCloseSuccessModal}/>
        ) : (
            <>
              <div className={"register__wrap"}>
                <div className={"left"}>
                  <>
                    <LabelGlobal labelText={"First name"} required={true}/>
                    <InputGlobal
                        placeholder={"First name"}
                        error={!!firstNameError}
                        errorText={firstNameError}
                        onChange={(e) => handleChangeName(e)}
                        name="first_name"
                        value={registrationData?.first_name ?? ""}
                        required={true}
                    />
                  </>
                  <>
                    <LabelGlobal labelText={"Last name"} required={true}/>
                    <InputGlobal
                        placeholder={"Last name"}
                        error={!!lastNameError}
                        errorText={lastNameError}
                        onChange={(e) => handleChangeName(e)}
                        name="last_name"
                        value={registrationData?.last_name ?? ""}
                    />
                  </>
                  <>
                    <LabelGlobal labelText={"Agency Name"} required={true}/>
                    <InputGlobal
                        placeholder={"Agency Name"}
                        error={!!agencyNameError}
                        errorText={agencyNameError}
                        onChange={(e) => handleChangeAgencyName(e)}
                        name="agency_name"
                        value={registrationData?.agency_name ?? ""}
                    />
                  </>
                  <>
                    <LabelGlobal labelText={"Your position"} required={true}/>
                    <InputGlobal
                        placeholder={"Your position"}
                        error={!!positionError}
                        errorText={positionError}
                        onChange={(e) => handleChangePosition(e)}
                        name="position"
                        value={registrationData?.position ?? ""}
                    />
                  </>
                  <>
                    <LabelGlobal
                        labelText={"Number of female clients"}
                        required={true}
                    />
                    <InputGlobal
                        placeholder={"Number of female clients"}
                        error={!!numberClientsError}
                        errorText={numberClientsError}
                        onChange={(e) => handleChangeNumberClient(e)}
                        name="number_client"
                        value={registrationData?.number_of_clients ?? ""}
                    />
                  </>
                </div>
                <div className={"right"}>
                  <>
                    <LabelGlobal
                        labelText={"Agency registration country"}
                        required={true}
                    />
                    <Select
                        name="registration_country"
                        options={countries}
                        onChange={handleChangeCountry}
                        placeholder={"Agency registration country"}
                        value={countries[registrationData?.residence_country] ?? ""}
                        error={!!countryError}
                        errorText={countryError}
                    />
                  </>
                  <>
                    <LabelGlobal labelText={"Phone number"} required={true}/>
                    <PhoneNumberInput
                        value={registrationData.phone ?? ""}
                        handleChange={handlePhoneNumberChange}
                        containerClass={"container__phone"}
                        dropdownClass={"dropdown__phone"}
                        inputClass={"input__phone"}
                        buttonClass={"dropdown__button"}
                        error={!!phoneNumberError}
                        errorText={phoneNumberError}
                    />
                  </>
                  <>
                    <LabelGlobal labelText={"Email"} required={true}/>
                    <InputGlobal
                        placeholder={"Email"}
                        error={!!emailError}
                        errorText={emailError}
                        onChange={(e) => handleChangeEmail(e)}
                        name="email"
                        value={registrationData?.email ?? ""}
                    />
                  </>
                  <>
                    <LabelGlobal labelText={"Comment"}/>
                    <TextArea
                        placeholder={"Comment"}
                        onChange={(e) => handleChangeComment(e)}
                        name="comment"
                        value={registrationData?.comment ?? ""}
                    />
                  </>
                </div>
              </div>
              <div className="g-recaptcha">
                <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_TOKEN}
                    onChange={onCaptchaChange}
                    ref={recaptchaRef}
                />
                {captchaError && <p className="error-text">{captchaError}</p>}
              </div>
              <button
                  className={"global__button form"}
                  onClick={handleRegistration}
              >
                Send request
              </button>
              <button className={"cancel__global"}>Cancel</button>
              {responseError && <p className={"error-text"}>{responseError}</p>}
            </>
        )}
      </>
  );
};
