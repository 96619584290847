import { get, post } from "./axios";
import { CONTACT_US_URL, LOGIN_URL, REGISTRATION_URL } from "../constants/auth";

/**
 * Get countries list
 * @returns {AxiosPromise<any>}
 */
export const getCountries = () => {
  return get("/assets-landing/countries.json");
};

/**
 * Registration user by api
 * @param {Object} data Request data
 * @return {Promise<AxiosResponse<*>>}
 */
export const registrationRequest = (data) => {
  return post(REGISTRATION_URL, data);
};

/**
 * Send request for contact us
 * @param {{data}} data Request data
 * @return {Promise<AxiosResponse<*>>}
 */
export const contactUsRequest = (data) => {
  return post(CONTACT_US_URL, data);
};

/**
 * Login user by api
 * @param {Object} data Request data
 * @return {Promise<AxiosResponse<*>>}
 */
export const loginRequest = (data) => {
  return post(LOGIN_URL, data);
};
