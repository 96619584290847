export const ERROR_STATUS_UNPROCESSABLE = 422;
export const STATUS_SUCCESS = 200;
export const MAX_LENGTH_TEXTAREA = 500;
export const CONST_USERNAME_MIN_SYMBOLS = 2;
export const CONST_USERNAME_MAX_SYMBOLS = 255;
export const USERNAME_REGEX = /^[a-zA-Z\s']+$/;
// eslint-disable-next-line
export const EMAIL_REGEXP =
  // eslint-disable-next-line
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const CONST_EMAIL_MAX_SYMBOLS = 255;
export const MAX_PASSWORD_LENGTH = 255;
export const MIN_PASSWORD_LENGTH = 5;
// eslint-disable-next-line
export const REGEX_PHONE = /^\+?\d{1,}[ -]?\(?\d{3}\)?[ -]?\d{3}[ -]?\d{4}$/;
// eslint-disable-next-line
export const REGEX_AGENCY = /^[a-zA-Z\-0-9]/;
export const REGEX_POSITION = /^[A-Za-z]+([\- ]?[A-Za-z]+)*$/;
export const REGEX_NUMBER_CLIENT = /^[0-9]+$/;
