/**
 * Core
 */
import SpinLoader, {
  LOADER_COLOR_DARK,
  LOADER_SIZE_LARGE,
  POSITION_ABSOLUTE,
} from "../SpinLoader";
import { useEffect, useRef, useState } from "react";
import { LabelGlobal } from "../LabelGlobal";
import { InputGlobal } from "../InputGlobal";
import { TextArea } from "../TextArea";
import ReCAPTCHA from "react-google-recaptcha";
import {
  clearForm,
  closeForm,
  closeSuccessModal,
  fixLayoutForSuccessModal,
  validateEmail,
  validateName,
} from "../../../helpers";
import {
  ERROR_STATUS_UNPROCESSABLE,
  MAX_LENGTH_TEXTAREA,
} from "../../../constants";
import { contactUsRequest } from "../../../services";
import { SuccessModal } from "../../Modals/SuccessModal";
import {
  CANNOT_BE_BLANK,
  CAPTCHA_ERROR,
  LONG_TEXT_ERROR,
  RESPONSE_CONTACTS_ERROR,
} from "../../../constants/errors";

export const ContactUs = () => {
  const [contactUsData, setContactUsData] = useState({});
  const [nameError, setNameError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [errorDescription, setErrorDescription] = useState(null);
  const [captchaError, setCaptchaError] = useState(null);
  const [responseError, setResponseError] = useState(null);
  const [loader, setLoader] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const recaptchaRef = useRef(null);

  /**
   * Clear form after close
   */
  const clearContactUsForm = () => {
    recaptchaRef.current?.reset();
    setContactUsData({});
    setNameError(null);
    setEmailError(null);
    setErrorDescription(null);
    setResponseError(null);
    setCaptchaError(null);
  };

  /**
   * Handle change Google Captcha
   * @param {string} value recaptcha
   */
  const onCaptchaChange = (value) => {
    setCaptchaError(null);
    setContactUsData({ ...contactUsData, reCaptcha: value });
  };

  /**
   * Handle change name
   * @param {object} event
   */
  const handleChangeName = (event) => {
    setNameError(null);
    setContactUsData({ ...contactUsData, name: event?.target?.value });
  };

  /**
   * Handle change  email ron contact us
   * @param {object} event
   */
  const handleChangeEmail = (event) => {
    setEmailError(null);
    setContactUsData({ ...contactUsData, email: event?.target?.value });
  };

  /**
   * Handle change message
   * @param {object} event
   */
  const handleDescription = (event) => {
    setErrorDescription(null);
    setContactUsData({ ...contactUsData, message: event?.target?.value });
  };

  /**
   * Handle for validation user data and send request on Api
   */
  const handleContactUs = () => {
    let isSuccessValidation = true;
    const resultName = validateName(contactUsData?.name);
    if (!resultName.status) {
      setNameError(resultName.error);
      isSuccessValidation = false;
    }
    const resultEmail = validateEmail(contactUsData.email);
    if (!resultEmail.status) {
      setEmailError(resultEmail.error);
      isSuccessValidation = false;
    }
    if (
      !contactUsData.message ||
      contactUsData.message.trim().length === 0 ||
      contactUsData.message.trim() === "undefined"
    ) {
      setErrorDescription(CANNOT_BE_BLANK);
      isSuccessValidation = false;
    } else if (contactUsData.message.length > MAX_LENGTH_TEXTAREA) {
      setErrorDescription(LONG_TEXT_ERROR);
    }
    if (!contactUsData?.reCaptcha) {
      setCaptchaError(CAPTCHA_ERROR);
      isSuccessValidation = false;
    }
    if (isSuccessValidation) {
      setLoader(true);
      successCbContactUs(
        contactUsData,
        () => {
          setLoader(false);
          setShowSuccessModal(true);
        },
        () => setLoader(false)
      );
    }
  };

  /**
   * Close Success Modal
   */
  const handleCloseSuccessModal = () => {
    closeSuccessModal(
      () => setShowSuccessModal(false),
      () => setContactUsData({}),
      ".form__wrap.contacts__form",
      ".form__header.contacts",
      false
    );
  };

  /**
   * Request Contact Us on api
   * @param {object} dataContacts
   * @param {function|null} cbSuccess
   * @param {function|null} errorCb
   */
  const successCbContactUs = (
    dataContacts,
    cbSuccess = null,
    errorCb = null
  ) => {
    contactUsRequest(dataContacts)
      .then(({ data }) => {
        if (data?.success) {
          setLoader(false);
          if (cbSuccess) {
            cbSuccess();
          }
        }
      })
      .catch(({ response }) => {
        setLoader(false);
        if (response.status === ERROR_STATUS_UNPROCESSABLE) {
          recaptchaRef.current?.reset();
          setContactUsData({ ...contactUsData, reCaptcha: "" });
          errorCb();
          const { data } = response;
          let errorText = "";
          data.errors.forEach((error) => {
            if (error.name === "name") {
              setNameError(error.message);
            } else if (error.name === "email") {
              setEmailError(error.message);
            } else if (error.name === "message") {
              setErrorDescription(error.message);
            } else if (error.name === "reCaptcha") {
              recaptchaRef.current?.reset();
              setCaptchaError(error.message);
            } else {
              errorText +=
                errorText.length > 0 ? " " + error.message : error.message;
            }
          });
          if (errorText.length !== 0) {
            setResponseError(errorText);
          }
        } else {
          setResponseError(RESPONSE_CONTACTS_ERROR);
          recaptchaRef.current?.reset();
          setContactUsData({ ...contactUsData, reCaptcha: "" });
        }
        errorCb();
      });
  };

  /*
   * Effect for clean Register Form
   */
  useEffect(() => {
    clearForm(".cancel__global", ".background__modal", ".close", () => {
      clearContactUsForm();
    });
  }, []);

  /*
   * Effect for layout success modal
   */
  useEffect(() => {
    if (!showSuccessModal) {
      closeContactsUs();
    }
    fixLayoutForSuccessModal(
      ".form__header.contacts",
      ".background__modal.contacts",
      "",
      showSuccessModal,
      () => setShowSuccessModal(false)
    );
  }, [showSuccessModal]);

  /**
   * Close Contact Us Modal
   */
  const closeContactsUs = () => {
    const contactsForm = document.querySelector(".form__wrap.contacts__form");
    const closeIcon = document.querySelector(".close.contacts");
    closeForm(closeIcon, contactsForm);
  };

  return (
    <>
      {loader && (
        <SpinLoader
          color={LOADER_COLOR_DARK}
          size={LOADER_SIZE_LARGE}
          position={POSITION_ABSOLUTE}
          withBlur={true}
          isFormBlur={true}
        />
      )}
      {showSuccessModal ? (
        <SuccessModal handleClick={handleCloseSuccessModal} />
      ) : (
        <div className={"contact__wrap"}>
          <>
            <LabelGlobal labelText={"Name"} />
            <InputGlobal
              placeholder={"Name"}
              error={!!nameError}
              errorText={nameError}
              onChange={(e) => handleChangeName(e)}
              name="name"
              value={contactUsData?.name ?? ""}
              required={true}
            />
          </>
          <>
            <LabelGlobal labelText={"Email"} />
            <InputGlobal
              placeholder={"Email"}
              error={!!emailError}
              errorText={emailError}
              onChange={(e) => handleChangeEmail(e)}
              name="email-contact"
              value={contactUsData?.email ?? ""}
            />
          </>
          <>
            <LabelGlobal labelText={"Message"} />
            <TextArea
              placeholder={"How can we help you?"}
              onChange={(e) => handleDescription(e)}
              name="comment"
              value={contactUsData?.message ?? ""}
              error={!!errorDescription}
              errorText={errorDescription}
            />
          </>
          <div className="g-recaptcha">
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_TOKEN}
              onChange={onCaptchaChange}
              ref={recaptchaRef}
            />
            {captchaError && (
              <p className="error-text captcha__contacts">{captchaError}</p>
            )}
          </div>
          <button
            className={"global__button contacts"}
            onClick={handleContactUs}
          >
            Send
          </button>
          {responseError && <p className={"error-text"}>{responseError}</p>}
        </div>
      )}
    </>
  );
};
