/**
 * Core
 */
import React from "react";
import PropTypes from "prop-types";

/**
 * Styles
 */
import "./SpinLoader.module.scss";

export const POSITION_ABSOLUTE = "l-absolute";
export const POSITION_FIXED = "l-fixed";
export const POSITION_STATIC = "l-static";

export const LOADER_COLOR_WHITE = "l-white";
export const LOADER_COLOR_RED = "l-red";
export const LOADER_COLOR_BLUE = "l-blue";
export const LOADER_COLOR_DARK = "l-dark";

export const LOADER_SIZE_LARGE = "l-large";
export const LOADER_SIZE_MEDIUM = "l-medium";
export const LOADER_SIZE_SMALL = "l-small";

const SpinLoader = ({
  position = POSITION_ABSOLUTE,
  size = LOADER_SIZE_LARGE,
  color = LOADER_COLOR_BLUE,
  withBlur = false,
  customClass = null,
}) => {
  return (
    <>
      {withBlur && (
        <div className={`spin-loader-blur ${position} ${customClass}`} />
      )}
      <div
        className={`spin-loader ${position} ${size} ${color} ${customClass}`}
      >
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </>
  );
};

SpinLoader.propTypes = {
  position: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  withBlur: PropTypes.bool,
  customClass: PropTypes.string,
};

export default SpinLoader;
