/**
 * Core
 */
import PropTypes from "prop-types";

/**
 * Assets
 */
import checkIconImg from "../../../images/success-check-icon.svg";
import closeIcon from "../../../images/ico_close.svg";

export const SuccessModal = ({ handleClick }) => {
  return (
    <div className="success__modal-wrap">
      <div className="close__wrap" onClick={handleClick}>
        <img src={closeIcon} alt="close-icon" />
      </div>
      <div className={"icon__wrap"}>
        <img src={checkIconImg} alt={"check-icon"} />
      </div>
      <span className="title">Request sent</span>
      <p className="text">
        Thank you for your request, our manager will contact you as soon as
        possible
      </p>
      <button className={"global__button success"} onClick={handleClick}>
        Accept
      </button>
    </div>
  );
};
SuccessModal.propTypes = {
  handleClick: PropTypes.func,
};
