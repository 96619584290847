/*
 * Core
 * */
import SpinLoader, {
  LOADER_COLOR_DARK,
  LOADER_SIZE_LARGE,
  POSITION_ABSOLUTE,
} from "../SpinLoader";
import { useEffect, useState } from "react";
import { LabelGlobal } from "../LabelGlobal";
import { InputGlobal } from "../InputGlobal";
import {
  clearForm,
  closeForm,
  validatePassword,
  validateUserIdentifier,
} from "../../../helpers";
import { ERROR_STATUS_UNPROCESSABLE, STATUS_SUCCESS } from "../../../constants";
import { loginRequest } from "../../../services";
import { RESPONSE_LOGIN_ERROR } from "../../../constants/errors";

/*
 * Assets
 * */
import EyeIconHide from "../../../images/ico_eye-off.svg";
import EyeIcon from "../../../images/ico_eye.svg";

export const LoginForm = () => {
  const [loginData, setLoginData] = useState({});
  const [userIdentifierError, setUserIdentifierError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [loader, setLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [responseError, setResponseError] = useState(null);

  /*
   * Effect for clear Form
   * */
  useEffect(() => {
    clearForm(".sign__up", ".background__modal", ".close", () => {
      clearLoginForm();
    });
  }, []);

  /*
   * Effect for close login form
   * */
  useEffect(() => {
    closeLoginForm();
  }, []);

  /*
   * Clean Form
   * */
  const clearLoginForm = () => {
    setUserIdentifierError(null);
    setPasswordError(null);
    setResponseError(null);
    setLoginData({});
  };

  /*
   * Open Registration Form and clean login user data
   * */
  const openRegisterForm = () => {
    const loginForm = document.querySelector(".form__wrap.login__form");
    const registerForm = document.querySelector(
      ".form__wrap.registration__form"
    );
    loginForm.style.display = "none";
    registerForm.style.display = "block";
    document.body.style.overflow = "hidden";
    clearLoginForm();
  };

  /*
   * Toggle show Password on click icon eye
   * */
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  /**
   * Handle change login email
   * @param {object} event
   */
  const handleChangeUserIdentifier = (event) => {
    setUserIdentifierError(null);
    setLoginData({ ...loginData, username: event?.target?.value });
  };

  /**
   * Handle change login password
   * @param {object} event
   */
  const handleChangePassword = (event) => {
    setPasswordError(null);
    setLoginData({ ...loginData, password: event?.target?.value });
  };

  /**
   * Close Login Modal
   */
  const closeLoginForm = () => {
    const loginForm = document.querySelector(".form__wrap.login__form");
    const closeIcon = document.querySelector(".close.login");
    closeForm(closeIcon, loginForm);
  };

  /**
   * Send request for login on Api
   */
  const handleLogin = () => {
    const resultEmail = validateUserIdentifier(loginData.username);
    if (!resultEmail.status) {
      setUserIdentifierError(resultEmail.error);
    }
    const resultPassword = validatePassword(loginData.password);
    if (!resultPassword.status) {
      setPasswordError(resultPassword.error);
    }
    if (resultEmail.status && resultPassword.status) {
      setLoader(true);
      loginRequest({ ...loginData, rememberMe: true })
        .then((resp) => cbLoginSuccess(resp))
        .catch((err) => responseErrorCb(err));
    }
  };

  /**
   * Success api response
   * @param {object} response APi response object
   */
  const cbLoginSuccess = (response) => {
    if (response?.data && response?.status === STATUS_SUCCESS) {
      const { data } = response;
      setLoginData({});
      window.location.href = data?.data?.redirect_url;
    } else {
      setLoader(false);
    }
  };

  /**
   * Failed request on api
   * @param {object} responseErr failed request on api
   */
  const responseErrorCb = (responseErr) => {
    setLoader(false);
    if (responseErr.response.status === ERROR_STATUS_UNPROCESSABLE) {
      const { data } = responseErr.response;
      let errorText = "";
      data.errors.forEach((error) => {
        if (error.name === "password") {
          setPasswordError(error.message);
        } else if (error.name === "username") {
          setUserIdentifierError(error.message);
        } else {
          errorText +=
            errorText.length > 0 ? " " + error.message : error.message;
        }
      });
    } else {
      setResponseError(RESPONSE_LOGIN_ERROR);
    }
  };

  return (
    <>
      {loader && (
        <SpinLoader
          color={LOADER_COLOR_DARK}
          size={LOADER_SIZE_LARGE}
          position={POSITION_ABSOLUTE}
          withBlur={true}
          isFormBlur={true}
        />
      )}
      <div className={"login__wrap"}>
        <>
          <LabelGlobal labelText={"Email or username"} />
          <InputGlobal
            placeholder={"name@example.com"}
            error={!!userIdentifierError}
            errorText={userIdentifierError}
            onChange={(e) => handleChangeUserIdentifier(e)}
            name="userIdentifier"
            value={loginData?.username ?? ""}
          />
          <LabelGlobal labelText={"Password"} />
          <InputGlobal
            placeholder={"Password"}
            error={!!passwordError}
            errorText={passwordError}
            onChange={(e) => handleChangePassword(e)}
            name="password"
            value={loginData?.password ?? ""}
            handleClickRightIcon={toggleShowPassword}
            passwordShowIcon={showPassword}
            type={showPassword ? "text" : "password"}
            rightIconSrc={showPassword ? EyeIconHide : EyeIcon}
            customClass={"password "}
          />
        </>
        <button className={"global__button form"} onClick={handleLogin}>
          Sign in
        </button>
        <div className={"dont__account"}>
          Don't have an account?
          <button className={"sign__up"} onClick={openRegisterForm}>
            {" "}
            Sign Up
          </button>
        </div>
        {responseError && <p className={"error-text"}>{responseError}</p>}
      </div>
    </>
  );
};
