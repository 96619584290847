/**
 * Core
 */
import ReactDOM from "react-dom/client";
import { RegistrationForm } from "./components/Auth/RegistrationForm";
import { LoginForm } from "./components/Auth/LoginForm";
import { ContactUs } from "./components/Auth/ContactUs";

/**
 * Styles
 */
import "./css/index.scss";

ReactDOM.createRoot(document.getElementById("registration")).render(
  <RegistrationForm />
);
ReactDOM.createRoot(document.getElementById("login")).render(<LoginForm />);
ReactDOM.createRoot(document.getElementById("contact-us")).render(
  <ContactUs />
);
