/**
 * Core
 */
import PropTypes from "prop-types";
/**
 * Styles
 */
import "./LabelGlobal.scss";

export const LabelGlobal = ({ labelText, required }) => {
  return (
    <>
      {labelText && (
        <span className={"label__global"}>
          {labelText}
          {required && <i>*</i>}
        </span>
      )}
    </>
  );
};
LabelGlobal.propTypes = {
  labelText: PropTypes.string,
  required: PropTypes.bool,
};
