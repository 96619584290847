/**
 * Core
 */
import "./TextArea.scss";
import PropTypes from "prop-types";

export const TextArea = ({
  placeholder,
  customClass,
  name,
  onChange,
  id,
  value,
  error,
  errorText,
}) => {
  return (
    <div className={"textarea__wrapper"}>
      <textarea
        autoComplete="off"
        placeholder={placeholder}
        className={
          "textarea " +
          (customClass ? customClass : "") +
          (error ? " error" : "")
        }
        name={name}
        onChange={onChange}
        id={id}
        value={value}
        rows="5"
      />
      {errorText && <p className={"error-text"}>{errorText}</p>}
    </div>
  );
};
TextArea.propTypes = {
  placeholder: PropTypes.string,
  customClass: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  id: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.bool,
  errorText: PropTypes.string,
};
