/**
 * Core
 */
import {
  CONST_EMAIL_MAX_SYMBOLS,
  CONST_USERNAME_MAX_SYMBOLS,
  CONST_USERNAME_MIN_SYMBOLS,
  EMAIL_REGEXP,
  MAX_PASSWORD_LENGTH,
  MIN_PASSWORD_LENGTH,
  REGEX_AGENCY,
  REGEX_NUMBER_CLIENT,
  REGEX_PHONE,
  REGEX_POSITION,
  USERNAME_REGEX,
} from "../constants";

/**
 * Validate username
 * @param {string} name User name
 * @return {{error: null, status: boolean}}
 */

export const validateName = (name) => {
  const result = {
    status: false,
    error: null,
  };

  if (
    !name ||
    name.length === 0 ||
    (typeof name.trim !== "undefined" && name.trim().length === 0)
  ) {
    result.error = "Cannot be blank";
    return result;
  }

  if (typeof name.trim !== "undefined") {
    name = name.trim();
  }

  if (name.length < CONST_USERNAME_MIN_SYMBOLS) {
    result.error = `The username must contain more than ${CONST_USERNAME_MIN_SYMBOLS} characters.`;
    return result;
  } else if (name.length > CONST_USERNAME_MAX_SYMBOLS) {
    result.error = `The username must contain less than ${CONST_USERNAME_MAX_SYMBOLS} characters.`;
    return result;
  } else if (!USERNAME_REGEX.test(name)) {
    result.error = `Only A-Z, a-z are allowed `;
    return result;
  }

  result.status = true;
  return result;
};

/**
 * Validate username
 * @param {string} credentials User
 * @return {{error: null, status: boolean}}
 */
export const validateUserIdentifier = (credentials) => {
  const result = {
    status: false,
    error: null,
  };
  if (
    !credentials ||
    credentials.length === 0 ||
    (typeof credentials.trim !== "undefined" && credentials.trim().length === 0)
  ) {
    result.error = "Cannot be blank";
    return result;
  }
  result.status = true;
  return result;
};

/**
 * Validate user email
 * @param {string} email User email
 */
export const validateEmail = (email) => {
  const result = {
    status: false,
    error: null,
  };

  if (!email || email.length <= 0) {
    result.error = "Cannot be blank";

    return result;
  }
  if (typeof email.trim !== "undefined") {
    email = email.trim();
  }

  if (email.length >= CONST_EMAIL_MAX_SYMBOLS) {
    result.error = `Email must contain less than ${CONST_EMAIL_MAX_SYMBOLS} characters.`;

    return result;
  } else if (!EMAIL_REGEXP.test(email)) {
    result.error = `Your email address is not valid. Please, check it.`;
    return result;
  }

  result.status = true;
  return result;
};

/**
 * Validate password
 * @param {string} password Password text
 * @return {{error: null|string, status: boolean}}
 */
export const validatePassword = (password) => {
  const result = {
    status: false,
    error: null,
  };

  if (
    !password ||
    password.length <= 0 ||
    (typeof password.trim !== "undefined" && password.trim().length === 0)
  ) {
    result.error = "Cannot be blank";
    return result;
  }
  if (password.length > MAX_PASSWORD_LENGTH) {
    result.error = `The password cannot be more than ${MAX_PASSWORD_LENGTH} characters.`;
    return result;
  }

  if (password.length < MIN_PASSWORD_LENGTH) {
    result.error = `The password cannot be less than ${MIN_PASSWORD_LENGTH} symbols.`;
    return result;
  }

  result.status = true;
  return result;
};

/**
 * Validate phone number
 * @param {string} phoneNumber Phone Number
 */
export const validatePhoneNumber = (phoneNumber) => {
  const result = {
    status: false,
    error: null,
  };
  if (
    !phoneNumber ||
    phoneNumber.length <= 0 ||
    (typeof phoneNumber.trim !== "undefined" && phoneNumber.trim().length === 0)
  ) {
    result.error = "Cannot be blank";
    return result;
  }
  if (!REGEX_PHONE.test(phoneNumber)) {
    result.error = "Invalid phone number format";
    return result;
  }
  result.status = true;
  return result;
};

/**
 * Validate agency name
 * @param {string} agencyName name of the agency
 */
export const validateAgencyName = (agencyName) => {
  const result = {
    status: false,
    error: null,
  };
  if (
    !agencyName ||
    agencyName.length === 0 ||
    (typeof agencyName.trim !== "undefined" && agencyName.trim().length === 0)
  ) {
    result.error = "Cannot be blank";
    return result;
  }

  if (typeof agencyName.trim !== "undefined") {
    agencyName = agencyName.trim();
  }
  if (!REGEX_AGENCY.test(agencyName)) {
    result.error = `Only A-Z, a-z, 0-9, are allowed `;
    return result;
  }
  result.status = true;
  return result;
};

/**
 * Validate position in agency
 * @param {string} position of agency
 */
export const validatePosition = (position) => {
  const result = {
    status: false,
    error: null,
  };
  if (
    !position ||
    position.length === 0 ||
    (typeof position.trim !== "undefined" && position.trim().length === 0)
  ) {
    result.error = "Cannot be blank";
    return result;
  }

  if (typeof position.trim !== "undefined") {
    position = position.trim();
  }
  if (!REGEX_POSITION.test(position)) {
    result.error = `Only A-Z, a-z, are allowed `;
    return result;
  }
  result.status = true;
  return result;
};

/**
 * Validate number phone client
 * @param {string} number of phone
 */
export const validateNumberClients = (number) => {
  const result = {
    status: false,
    error: null,
  };
  if (
    !number ||
    number.length === 0 ||
    (typeof number.trim !== "undefined" && number.trim().length === 0)
  ) {
    result.error = "Cannot be blank";
    return result;
  }

  if (typeof number.trim !== "undefined") {
    number = number.trim();
  }
  if (!REGEX_NUMBER_CLIENT.test(number)) {
    result.error = "Only 0-9 are allowed";
    return result;
  }
  result.status = true;
  return result;
};

/**
 * Clear Forms
 * @param {string} closeAttr
 * @param {string} bgAttr
 * @param {string} additionalAttr
 * @param {function} cb
 */
export const clearForm = (closeAttr, bgAttr, additionalAttr, cb) => {
  const closeForm = document.querySelectorAll(closeAttr);
  const modalBgRef = document.querySelectorAll(bgAttr);
  const closeIcon = document.querySelectorAll(additionalAttr);

  closeForm.forEach((btn) => {
    btn.addEventListener("click", () => {
      cb();
    });
  });

  closeIcon.forEach((btn) => {
    btn.addEventListener("click", () => {
      cb();
    });
  });

  modalBgRef.forEach((bg) => {
    bg.addEventListener("click", (e) => {
      if (e.target === e.currentTarget) {
        cb();
      }
    });
  });
};

/**
 * Fix Layout Success Modal
 * @param {string} headerAttr
 * @param {string} bgAttr
 * @param {string} formAttr
 * @param{boolean} showSuccess,
 * @param {function} cb
 */
export const fixLayoutForSuccessModal = (
  headerAttr,
  bgAttr,
  formAttr,
  showSuccess,
  cb
) => {
  const headerForm = document.querySelector(headerAttr);
  const backgroundModal = document.querySelector(bgAttr);
  if (showSuccess) {
    headerForm.classList.add("success");
    if (formAttr) {
      const formWrapper = document.querySelector(formAttr);
      formWrapper.classList.add("success");
    }
    backgroundModal.classList.add("fixed");
    backgroundModal.addEventListener("click", () => {
      cb();
      headerForm.classList.remove("success");
    });
  }
};

/**
 * Close Success Modal
 * @param {function} showModalCb
 * @param {function} setDataCb
 * @param {string} formAttr
 * @param{string} headerAttr,
 * @param {boolean} innerFormAttr
 */
export const closeSuccessModal = (
  showModalCb,
  setDataCb,
  formAttr,
  headerAttr,
  innerFormAttr
) => {
  showModalCb();
  setDataCb();
  const contactUsForm = document.querySelector(formAttr);
  const headerForm = document.querySelector(headerAttr);
  if (innerFormAttr) {
    const formInner = document.querySelector(".form__inner.register");
    formInner.classList.remove("success");
  }
  contactUsForm.style.display = "none";
  document.body.style.overflow = "auto";
  headerForm.classList.remove("success");
};

/**
 * Close register form
 */
export const closeForm = (attr, target) => {
  attr.addEventListener("click", (e) => {
    e.preventDefault();
    target.style.display = "none";
    document.body.style.overflow = "unset";
  });
};
