/**
 * Core
 */
import PropTypes from "prop-types";
/**
 * Styles
 */
import "./InputGlobal.scss";

export const InputGlobal = ({
  placeholder,
  customClass,
  name,
  type,
  onChange,
  id,
  value,
  error,
  errorText,
  rightIconSrc = null,
  handleClickRightIcon = null,
  passwordShowIcon = null,
}) => {
  return (
    <div className={"input__wrapper"}>
      <div className={"input__wrapper-inner"}>
        <input
          autoComplete="off"
          type={type}
          placeholder={placeholder ?? ""}
          className={
            "input__global " +
            (customClass ? customClass : "") +
            (error ? "error" : "")
          }
          onChange={onChange}
          name={name}
          id={id ?? `${name}Id`}
          value={value ?? ""}
        />
        {rightIconSrc && (
          <div
            className={
              "icon__right" +
              (handleClickRightIcon ? " icon__right-active" : "") +
              (passwordShowIcon ? " icon__show" : "")
            }
            onClick={handleClickRightIcon ?? null}
          >
            <img src={rightIconSrc} alt="Input Icon" />
          </div>
        )}
      </div>
      {errorText && <p className={"error-text form"}>{errorText}</p>}
    </div>
  );
};
InputGlobal.propTypes = {
  placeholder: PropTypes.string,
  customClass: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  id: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  rightIconSrc: PropTypes.string,
  passwordShowIcon: PropTypes.bool,
  handleClickRightIcon: PropTypes.func,
};
